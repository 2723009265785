<template>
  <vx-card title="Sales Area">
    <vs-tabs>
      <vs-tab label="Sales Area">
        <territory-area></territory-area>
      </vs-tab>
      <vs-tab label="Territory">
        <div class="tab-text">
          <territory></territory>
        </div>
      </vs-tab>
      <vs-tab label="Warehouse Territory">
        <div class="tab-text">
          <warehouse-territory></warehouse-territory>
        </div>
      </vs-tab>
      <vs-tab label="Zone">
        <div class="tab-text">
          <zone></zone>
        </div>
      </vs-tab>
      <vs-tab label="CMI">
        <div class="tab-text">
          <cmi></cmi>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import TerritoryArea from "./TerritoryArea";
import Territory from "./Territory";
import WarehouseTerritory from "./WarehouseTerritory";
import Zone from "./Zone";
import Cmi from "./Cmi";
export default {
  components: {
    TerritoryArea,
    Territory,
    WarehouseTerritory,
    Zone,
    Cmi
  }
};
</script>