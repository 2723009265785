<template>
  <div class="w-5/6 ml-auto mr-auto vx-col">
    <span>
      <b v-if="cmiId != null">Edit CMI</b>
      <b v-else>Create CMI</b>
    </span>
    <div class="mb-6 vx-row">
      <div class="w-full vx-col">
        <label class="vs-input--label">Territory</label>
        <multiselect
          v-model="territorySelected"
          :options="territoryOptions"
          placeholder="Type to search"
          label="name"
          track-by="code"
          :max-height="125"
          :disabled="cmiId != null"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span
          class="text-sm text-danger"
          v-show="errors.has('Territory')"
          >{{ errors.first("Territory") }}</span
        >
      </div>
    </div>
    <div class="mb-6 vx-row">
      <div class="w-full vx-col">
        <vs-input
          class="w-full"
          name="CompanyID"
          label="CompanyID"
          v-model="data.companyId"
          v-validate="'required'"
        />
        <span class="text-sm text-danger" v-show="errors.has('Company')">{{
          errors.first("Company")
        }}</span>
      </div>
    </div>
    <div class="mb-6 vx-row">
      <div class="w-full vx-col">
        <vs-input
          class="w-full"
          name="BranchID"
          label="BranchID"
          v-model="data.branchId"
          v-validate="'required'"
        />
        <span class="text-sm text-danger" v-show="errors.has('Branch')">{{
          errors.first("Branch")
        }}</span>
      </div>
    </div>
    <div class="mb-6 vx-row">
      <div class="w-full vx-col">
        <vs-input
          class="w-full"
          name="DivisionID"
          label="DivisionID"
          v-model="data.divisionId"
          v-validate="'required'"
        />
        <span class="text-sm text-danger" v-show="errors.has('Division')">{{
          errors.first("Division")
        }}</span>
      </div>
    </div>
    <div class="mb-6 vx-row">
      <div class="w-full vx-col">
        <vs-input
          class="w-full"
          name="DepoCode"
          label="DepoCode"
          v-model="data.depoCode"
          v-validate="'required'"
        />
        <span class="text-sm text-danger" v-show="errors.has('DepoCode')">{{
          errors.first("DepoCode")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="w-full vx-col">
        <vs-button class="mb-2 mr-3" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="mt-2 ml-4"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    cmiId: {
      type: Number,
    },
    baseUrl: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        territoryOptions: [],
        territorySelected: null,
        data: {
          territoryId: null,
          territoryCode: "",
          territoryName: "",
          companyId: "",
          branchId: "",
          divisionId: "",
          depoCode: "",
        },
      };
    },
    handleSubmit() {
      if (!this.data.territoryId) {
        this.errors.add({
          field: "Territory",
          msg: "The Territory field is required",
        });
      } else if (this.data.companyId == "") {
        this.errors.clear();
        this.errors.add({
          field: "Company",
          msg: "The Company field is required",
        });
      } else if (this.data.branchId == "") {
        this.errors.clear();
        this.errors.add({
          field: "Branch",
          msg: "The Branch field is required",
        });
      } else if (this.data.divisionId == "") {
        this.errors.clear();
        this.errors.add({
          field: "Division",
          msg: "The Division field is required",
        });
      } else if (this.data.depoCode == "") {
        this.errors.clear();
        this.errors.add({
          field: "DepoCode",
          msg: "The DepoCode field is required",
        });
      } else {
        this.errors.clear();
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$vs.loading();
            if (this.cmiId) {
              this.putData();
            } else {
              this.postData();
            }
          }
        });
      }
    },
    paramData() {
      return {
        territory_id: this.data.territoryId,
        territory_code: this.data.territoryCode,
        territory_name: this.data.territoryName,
        company_id: this.data.companyId,
        branch_id: this.data.branchId,
        division_id: this.data.divisionId,
        depo_code: this.data.depoCode,
      };
    },
    postData() {
      console.log("PARAM DATA",this.paramData())
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New CMI Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      console.log("PARAM DATA",this.paramData())
      this.$http
        .put(this.baseUrl + "/" + this.cmiId, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "CMI Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.cmiId) {
        this.$http
          .get(this.baseUrl + "/" + this.cmiId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.territorySelected = {
                id: resp.data.territory_id,
                code: resp.data.territory_code,
                name: resp.data.territory_name
              }
              this.data.companyId = resp.data.company_id;
              this.data.branchId = resp.data.branch_id;
              this.data.divisionId = resp.data.division_id;              
              this.data.depoCode = resp.data.depo_code;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    getAllDataTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory")
        .then((resp) => {
          if (resp.status == "success") {
            this.territoryOptions = resp.data.records.map(record => ({
              id: record.id,
              code: record.code,
              name: record.name,
            }));
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
  },
  mounted() {
    this.getAllDataTerritory();
    if (this.cmiId) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    cmiId(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
      this.getAllDataTerritory();
    },
    territorySelected(val) {
      if (val) {
        this.errors.clear();
        this.data.territoryId = val.id;
        this.data.territoryCode = val.code;
        this.data.territoryName = val.name;
      } else {
        this.data.territoryId = null;
        this.data.territoryCode = "";
        this.data.territoryName = "";
      }
    },
  },
};
</script>
